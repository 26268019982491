import Link from "next/link";
import Layout from "~/components/Layout";

const PageNotFound = () => (
  <Layout title="Pagina non trovata" robotsContent="noindex, nofollow">
    <section className="-mt-12 w-full">
      <div className="mx-auto max-w-box px-4 pb-32 pt-24">
        <div className="mb-20 lg:mb-32">
          <h1 className="mb-7 text-4xl font-light md:text-6xl">
            Pagina non trovata!
          </h1>
          <h2 className="mb-7  text-3xl">
            Non siamo riusciti a trovare la pagina che stai cercando.
          </h2>
          <p className="text-xl">
            <Link
              href="/"
              className="mr-10 inline-block text-orange underline hover:opacity-80"
            >
              Torna alla pagina iniziale! ›
            </Link>
            <Link
              href="/ricercaAvanzata"
              className="inline-block text-orange underline hover:opacity-80"
            >
              Vai alla pagina di ricerca ›
            </Link>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default PageNotFound;
